import { getIndustriapi } from "../../_helper/services/industries";
import { industriesget, industriesgetFail, industriesgetSuccess } from "./action";
import { INDUSTRIES_GET } from "./actiontype";
import { all, takeLatest, put, call } from 'redux-saga/effects';

function* onGetIndustries({ payload :payload}) {

  try {
    
    const response = yield call(getIndustriapi, payload);
 
    yield put(industriesgetSuccess(response));
  } catch (error) {
 
    yield put(industriesgetFail(error.response));
  }
}

function* IndustriesSaga() {
  yield takeLatest(INDUSTRIES_GET, onGetIndustries);
 


}
export default IndustriesSaga;