import { INDUSTRIES_GET, INDUSTRIES_GET_FAIL, INDUSTRIES_GET_SUCCESS } from "./actiontype"

export const industriesget = ()=>{
    return{
        type:INDUSTRIES_GET,
       
    }
}
export const industriesgetSuccess = (data)=>{
    return{
        type:INDUSTRIES_GET_SUCCESS,
        payload:data
    }
}
export const industriesgetFail = (error)=>{
    return{
        type:INDUSTRIES_GET_FAIL,
        payload:error
    }
}