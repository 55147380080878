import React, { useEffect, useState } from 'react'
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
// import "../login/login.scss";
import "./registration.scss"
import InfoIcon from '@mui/icons-material/Info';

import { useForm } from 'react-hook-form';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate } from 'react-router-dom';
import { axiosApi } from '../../_helper/api_helper';
import { ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material';
import LoadingPannel from '../../commonComponent/LoadingPannel/LoadingPannel';
import { Helmet } from 'react-helmet';
import ReactCountryFlag from 'react-country-flag';

const countryMapping = {
  IN: 'India',
  AU: 'Australia',
};


const OtherCountries = () => {
  const navigate = useNavigate()



  const clientId = localStorage.getItem("client_Id")

  // const [error, setError] = useState()
  const { control, setValue, reset, register, getValues } = useForm();
  const [formavalues, setFormvalues] = useState({
    mobile: "",
    coupon_code: "",
    otp: "",
    email: "",
  });
  const [minutes, setMinutes] = useState(60);
  const [seconds, setSeconds] = useState(0);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [emailcheck, setEmailCheck] = useState('');
  const [coupenCode, setCoupenCode] = useState('')
  const [startTimer, setStartTimer] = useState(false);
  const [loginResponse, setLoginResponse] = useState({});
  const [loading, setLoading] = useState(false)
  // const [personalDetail, setPeronalDetailContent] = useState({});
  const [applyCoupen, setApplyCoupen] = useState(false)
  const [selectedCountry, setSelectedCountry] = useState("AU");
  
  const handleChnage = (e) => {
    const value = e.target.value.trim();

    // Regex pattern to check if input is an email
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Regex pattern to check if input is a valid phone number (allowing digits only)
    const phonePattern = /^\d{10}$/;

    
    if (emailPattern.test(value)) {
      setFormvalues({ ...formavalues, email: value, mobile: "" }); // Clear mobile field when it's an email
    }
     
    else if (phonePattern.test(value)) {
      setFormvalues({ ...formavalues, mobile: value, email: "" }); // Clear email field when it's a phone number
    }
  };
  const handleChnageOtp = (e) => {
    setFormvalues({ ...formavalues, [e.target.name]: e.target.value });
  }


  useEffect(() => {

  }, [loginResponse]);

 

  const handleCountryChange = (country) => {
    setSelectedCountry(country);
  };

  const handleOutsideCountry = async () => {
     
    setLoading(true);
    try {
      // const trimmedMobile = formavalues.mobile.trim();
      const trimmedEmail = formavalues.email.trim();
      // setPhoneNumber(trimmedMobile);
      setEmailCheck(trimmedEmail)
      // setPhoneNumber(formavalues.mobile);
      // setCoupenCode(formavalues?.coupon_code)

      let payload = {
        coupon_code: formavalues.coupon_code,
      };

      // Add mobile or email based on which is provided
      if (trimmedEmail) {
        payload.email = trimmedEmail;
      }
      const response = await axiosApi.post("otp-send", payload);

      setLoginResponse(response?.data);
      toast.success(response?.data?.message, {
        position: 'top-right',

      });

      // setStartTimer(true);
    } catch (error) {
      toast.error('Please Provide Correct details', {
        position: 'top-right',
      });
    } finally {
      // Turn off loading whether API call is successful or not
      setLoading(false);
    }
  }

  const handleSubmit = async () => {

 
    setLoading(true);
    try {
      const trimmedMobile = formavalues.mobile.trim();
      const trimmedEmail = formavalues.email.trim();
      const sanitizeMobileNumber = (mobile) => {
        // Remove leading '+91' if present
        if (mobile.startsWith('+91')) {
            mobile = mobile.slice(3);
        }
        // Remove leading '0' if present
        if (mobile.startsWith('0')) {
            mobile = mobile.slice(1);
        }
        // Return sanitized mobile number
        return mobile;
    };
    const sanitizedMobile = trimmedMobile ? sanitizeMobileNumber(trimmedMobile) : null;

    setPhoneNumber(sanitizedMobile || trimmedMobile);
      setEmailCheck(trimmedEmail)
      // setPhoneNumber(formavalues.mobile);
      setCoupenCode(formavalues?.coupon_code)

      let payload = {
        coupon_code: formavalues.coupon_code,
      };
     
      // Add mobile or email based on which is provided
      if (sanitizedMobile) {
        payload.mobile = sanitizedMobile;
      } else if (trimmedEmail) {
        payload.email = trimmedEmail;
      }
      const response = await axiosApi.post("otp-send", payload);

      setLoginResponse(response?.data);
      toast.success(response?.data?.message, {
        position: 'top-right',

      });
      setMinutes(1); // Reset timer to 1 minute
      setSeconds(0);
      setStartTimer(true);
      // setStartTimer(true);
    } catch (error) {
      toast.error('Please Provide Correct details', {
        position: 'top-right',
      });
    } finally {
      // Turn off loading whether API call is successful or not
      setLoading(false);
    }

  }

  const onSubmitOtp = async (data) => {
    setLoading(true);

    try {
      const payload = {
        otp: formavalues?.otp,
        mobile: phoneNumber,

        email: formavalues.email,
        country_name: countryMapping[selectedCountry],


      }



      const response = await axiosApi.post('otp-verify', payload);
      toast.success(' OTP Verify.', {
        position: 'top-right',
      });
      localStorage.removeItem("recuiter_Id");

      localStorage.setItem("country_name", countryMapping[selectedCountry])
      localStorage.setItem("client_Id", response?.data.client?.id);
      const clientId = response?.data.client?.id;
      axiosApi.get(`/clientdata/${clientId}`).then((response) => {

        if (response?.data?.client?.status == 1) {
          navigate("/")

          window.location?.reload()

        } else if (response?.data?.client?.filled_status == 1) {
          navigate("/profile-completed")
          window.location?.reload()

        } else {
          navigate("/profile")

          window.location?.reload()

        }
      })




    } catch (error) {

      toast.error(error?.response?.data?.error?.message, {
        position: 'top-right',
      });
    } finally {
      // Turn off loading whether API call is successful or not
      setLoading(false);
    }


  }

  useEffect(() => {
    if (startTimer) {
      const interval = setInterval(() => {
        setSeconds((prevSeconds) => {
          if (prevSeconds > 0) {
            return prevSeconds - 1;
          } else if (minutes > 0) {
            setMinutes((prevMinutes) => prevMinutes - 1);
            return 59; 
          } else {
            clearInterval(interval);
            return 0;  
          }
        });
      }, 1000);
  
      return () => clearInterval(interval); // Clean up interval
    }
  }, [startTimer, minutes]);


  const resendOTP = () => {
    setMinutes(2);
    setSeconds(0);
    setStartTimer(true);
    // onSubmit()
    handleSubmit()
  };


  return (
    <>
      <Helmet>
        <title>Join NearbyFreelancer | Register as a Freelancer</title>
        <meta name="description" content="Sign up with NearbyFreelancer to showcase your skills and expertise to recruiters. Create your freelancer profile and start connecting with potential clients looking for your talents" />

      </Helmet>
      {loading && LoadingPannel()}
      <div className=" registation-continer" >
        <div className="registation-freelancer">


          {/* Always show on all devices */}
          <div className="login-flex-second ">
            <div className="login-flex-second-continer">

              <img src="/image/man-using-laptop-desk.jpg" className='img-fluid' alt="" />




            </div>
          </div>
          <div className="   login-flex-first ">
            <div className='freelancer-logo-login'><Link to='/'><img src="/image/nbflogo.png" alt="nbf" /></Link></div>

            <div className="login-flex-first-continer">
              <h4>Freelancer Login</h4>
              <h6>Start Your Freelancing Journey With Us.</h6>

              {!loginResponse || loginResponse.status !== "ok" ? (
                <>
                  <div className='login-flag'>

                    <div style={{ fontSize: "14px", color: "grey" }} className='ms-1'>Country  <span>
                      <Tooltip placement="top" title={
                        <>

                          <div>Select the country</div>


                        </>
                      } arrow>
                        <InfoIcon style={{ color: "grey" }} />
                      </Tooltip>
                    </span>
                    </div>



                    <div className="country-flag-regin">
                      {selectedCountry && <ReactCountryFlag countryCode={selectedCountry} svg style={{ fontSize: '2em', marginRight: '10px' }} />}
                      <select

                        onChange={(e) => handleCountryChange(e.target.value)}
                        value={selectedCountry}

                      >
                        <option value=''>Select your country</option>
                        <option value="IN"><span style={{ fontFamily: "cursive" }}>IN</span>  India  </option>

                        <option value="AU"> <span style={{ fontFamily: "cursive" }}>AU</span> Australia   </option>

                      </select>
                    </div>

                  </div>
                  {selectedCountry && (
                    selectedCountry === "AU" ? (
                      <>
                        {/* Render this section for Australia */}
                        <TextField
                          size="large"
                          id="outlined-basic"
                          label="Enter your email"
                          type="email"
                          name="inputField"
                          variant="outlined"
                          onChange={handleChnage}
                          className="text-fieldss"
                        />
                        <Typography className="freelancer-registration-button">
                          <button onClick={handleOutsideCountry} className="">Get OTP</button>
                        </Typography>
                      </>
                    ) : (
                      <>
                        {/* Render this section for other countries */}
                        <TextField
                          size="large"
                          id="outlined-basic"
                          label="Enter your email and phone number"
                          type="text"
                          name="inputField"
                          variant="outlined"
                          onChange={handleChnage}
                          className="text-fieldss"
                        />
                        <Typography className="freelancer-registration-button">
                          <button onClick={ handleSubmit} className="">Get OTP</button>
                        </Typography>
                      </>
                    )
                  )}




                </>
              ) : (

                <>
                  <TextField
                    size="large"
                    id="outlined-basic"
                    label="OTP"
                    name='otp'
                    variant="outlined"
                    value={formavalues?.otp}
                    className='text-fieldss'
                    onChange={handleChnageOtp}

                  />

                  <div className='otp-sent-freelancer'>   OTP sent on: <strong>{phoneNumber ? `+91- ${phoneNumber}` : emailcheck}</strong> </div>
                  {/* {coupenCode && <div>   Coupon Code : <span style={{ background: "lightGrey", padding: "0.6rem" }}>{coupenCode}</span> </div>} */}


                  <Typography
                    className='d-flex align-items-center'

                  >
                    {seconds > 0 || minutes > 0 ? (
                      <p>
                        Time Remaining: {seconds}
                      </p>
                    ) : (
                      <p>Didn't recieve code?</p>
                    )}
                    <p
                      className='btn btn-link'
                      disabled={seconds > 0 || minutes > 0}
                      style={{
                        color: seconds > 0 || minutes > 0 ? "#DFE3E8" : "#FF5630",
                      }}
                      onClick={resendOTP}
                    >
                      Resend OTP
                    </p>
                  </Typography>

                  <Typography style={{ width: '90%', }}>
                    <button style={{ width: '100%', borderRadius: "12px", background: "#FF3131", color: "white", fontSize: "17px" }} onClick={onSubmitOtp} className="btn btn py-2">Verify OTP</button>
                  </Typography>

                </>


              )}
            </div>
            <ToastContainer />
          </div>
        </div>
      </div>


    </>
  )
}

export default OtherCountries
