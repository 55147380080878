import { GETEDUCATION, CREATEEDUCATION, DELETEEDUCATION } from "./actiontype";
import { all, takeLatest, put, call } from 'redux-saga/effects';
import {
  getEducationSuccess,
  getEducationFail, createEducationSuccess, createEducationFail,
  deleteEducationSuccess, deleteEducationFail
} from "./action";
import { saveEducationApi, getEducationApi, deleteEducationApi } from "../../_helper/services/educationregistration";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function* onGetEducation({ payload: clientId }) {

  try {
    const response = yield call(getEducationApi, clientId);
    yield put(getEducationSuccess(response));
  } catch (error) {
    yield put(getEducationFail(error.response));
  }
}
function* onSaveEducation({ payload: { clientId, payload } }) {

  try {
    const response = yield call(saveEducationApi, { clientId, payload });
    yield put(createEducationSuccess(response));
    toast.success('Education  SuccessFully Added', {
      position: 'top-left'
    });
  } catch (error) {
    yield put(createEducationFail(error.response));
  }
}
function* OnDeleteEducaton({ payload: { CLIENT_ID, EDUCATION_ID } }) {
   
  try {
    const confirmed = window.confirm("Are you sure you want to delete?");
    if (confirmed) {
      const response = yield call(deleteEducationApi, { CLIENT_ID, EDUCATION_ID });
      yield put(deleteEducationSuccess(response));
      toast.success('Delete  SuccessFully  ', {
        position: 'top-left'
      });
    }

  } catch (error) {
    yield put(deleteEducationFail(error.response));
  }
  // finally {
  //   yield put(resetDeleteMenu());  
  // }
}
<ToastContainer />


function* EducationSaga() {
  yield takeLatest(GETEDUCATION, onGetEducation);
  yield takeLatest(CREATEEDUCATION, onSaveEducation);
  yield takeLatest(DELETEEDUCATION, OnDeleteEducaton);


}

export default EducationSaga;