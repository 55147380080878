import React, { useEffect, useRef, useState } from 'react'
import "./jobpostwihoutlogin.scss";
import { axiosApi } from '../../_helper/api_helper';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Switch from '@mui/material/Switch';
import CancelIcon from '@mui/icons-material/Cancel';
import { TextField, Tooltip, Typography } from '@mui/material';
import { toast, ToastContainer } from "react-toastify";
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import DeleteIcon from '@mui/icons-material/Delete';
import LoadingPannel from '../../commonComponent/LoadingPannel/LoadingPannel';


const JobPostLogin = () => {


    const navigate = useNavigate();

    const [minutes, setMinutes] = useState(60);
    const [seconds, setSeconds] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [recuiterId, setRecuiterId] = useState(null);
    const [industries, setIndustries] = useState([]);
    const [citiesGetData, setCitiesGetData] = useState([])
    const [subcategories, setSubcategories] = useState([]);
    const [selectedIndustry, setSelectedIndustry] = useState("");
    const [payHour, setPayHour] = useState(false);
    const [fixedPrice, setFixedPrice] = useState(false);
    const [manySkills, setManySkill] = useState([]);
    const [skill, setSkill] = useState('');
    const [validationMsg, setValidationMsg] = useState('');

    const [loading, setLoading] = useState(false)
    const [formValues, setformValues] = useState({
        recuiter_name: '',
        job_title: '',
        job_description: '',
        country_name: "India",
        skills: [],
        project_budget_hr: "",
        project_budget: "",
        skill: "",
        occupation_skill: "",
        industry: "",
        // city_new: '',
        job_city_new: "",
        remote_job: false,
        job_salary: "",
        job_salary_usd: '',
    });
    const [selectedIndustryName, setSelectedIndustryName] = useState('');
    const [selectedSkillName, setSelectedSkillName] = useState('');

    const [startTimer, setStartTimer] = useState(false);
    const [loginRecuitar, setLoginRecuiter] = useState({});
    const [isOtpSent, setIsOtpSent] = useState(false);
    const label = { inputProps: { 'aria-label': 'Size switch demo' } };

    const [countries, setCountries] = useState([]);
    const [countryData, setCountryData] = useState([]);
    const filterCountry = countries.find((d) => d.country_name == formValues?.country_name)?.id
    const totalPages = 5;
    const salaryRangeMap = {
        'Less than 1 LPA': { min: 0, max: 1 },
        '1 to 2 LPA': { min: 1, max: 2 },
        '2 TO 3 LPA': { min: 2, max: 3 },
        '3 to 4 LPA': { min: 3, max: 4 },
        '4 to 5 LPA': { min: 4, max: 5 },
        '5 to 8 LPA': { min: 5, max: 8 },
        '8 to 10 LPA': { min: 8, max: 10 },
        '10 to 15 LPA': { min: 10, max: 15 },
        '15+ LPA': { min: 15, max: Infinity }
    };
 

    const [projectdescp, setProjectDescp] = useState({
        input_prompt: ""
    });
    const [apiResponse, setApiResponse] = useState(null); // To hold the API response



    const handleDecpChange = (e) => {
        setProjectDescp({ ...projectdescp, [e.target.name]: e.target.value });
    };

    const descriptionapi = async () => {

        setLoading(true)

        try {

            const payload = {
                input_prompt: projectdescp?.input_prompt
            }

            const response = await axiosApi.post(`/generate-job-discription`, payload);
            setApiResponse(response.data);

            setformValues((prevValues) => ({
                ...prevValues,

                job_description: response.data?.job_description || '',
            }));
            const result = await axiosApi.post(`/generate-job-title`, payload);
            setApiResponse(response.data);

            setformValues((prevValues) => ({
                ...prevValues,
                job_title: result.data?.job_title || '',

            }));



            setLoading(false)


   
        } catch (error) {
            toast.error('Please type few points.', { position: 'top-left' });
            setLoading(false)

            console.error("Error fetching description:", error);
        }
    }
    const handleRemoteJobChange = (event) => {
        setformValues({
            ...formValues,
            remote_job: event.target.checked,
        });
    };
    const handleSalaryProject = (e) => {
        const { name, value } = e.target;
        setformValues((prevValues) => {
            const updatedValues = { ...prevValues, [name]: value };

            if (name === "job_salary") {
                const range = salaryRangeMap[value] || { min: '', max: '' };
                updatedValues.min_salary = range.min;
                updatedValues.max_salary = range.max;
            }

            return updatedValues;
        });
    }
    const handleGenerateSkills = async () => {
        const payload = {
            industry: selectedIndustryName,
            subcategory: selectedSkillName,
        };

    

        try {
            const response = await axiosApi.post(`/generate-skills`, payload);

   
            setManySkill(response?.data?.skills)
        } catch (error) {
            console.error("Error:", error);
        }
    };


    const handleConfirmation = (useApiData) => {
        if (!useApiData) {
            // Clear fields for manual editing
            setformValues((prevValues) => ({
                ...prevValues,
                job_title: '',
                job_description: '',
            }));
            // User wants to edit manually
        }
    };


    const handleChangeskill = (e) => {
        setSkill(e.target.value);
    };
    const handleskillSoftware = (e) => {
        e.preventDefault();
        if (skill.trim()) {
            setManySkill([...manySkills, skill]);
            setSkill('');
        }
    };


    const handleSkillDelete = (index) => {
        // const newSkills = manySkills.filter((_, i) => i !== index);
        // setManySkill(newSkills);
        const updatedSkills = manySkills.filter((_, i) => i !== index);
        setManySkill(updatedSkills);
    };

    const textareaRef = useRef(null);
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setformValues({ ...formValues, [name]: value });
        adjustHeight();
    };
    const adjustHeight = () => {
        if (textareaRef.current) {
            textareaRef.current.style.height = '10px'; // Reset the height first
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`; // Set it to scrollHeight to fit content
        }
    };


    const handleNext = async () => {
        if (currentPage === 1 && !apiResponse) {
            // Only call the API if on page 1 and there is no response yet
            await descriptionapi();
        } else if (validatePage()) {
            // Move to the next page if validation passes
            setCurrentPage(currentPage + 1);
            await handleGenerateSkills();
        }
    };
    // const handleNext = async () => {

    //     if (validatePage()) {
    //         setCurrentPage(currentPage + 1);
    //         await handleGenerateSkills();
    //     }
    // };
    const handlePrev = () => {
        if (currentPage > 0) {
            setCurrentPage((prevPage) => prevPage - 1);
        }
    };
    useEffect(() => {

        citiesGet();
        countriesApi()
    }, [])
    const citiesGet = async () => {
        const response = await axiosApi.get("/cities");
        setCitiesGetData(response?.data)
    }
    const countriesApi = async () => {
        const response = await axiosApi.get("/countries");
        setCountries(response?.data)
    }

    const countriesGetById = async () => {
        try {
            const response = await axiosApi.get(`/countries/${filterCountry}/cities`);
            // const sortedData = response?.data?.sort((a, b) =>
            //     a.city_name.localeCompare(b.city_name)
            // );  
            const sortedData = response?.data?.sort((a, b) => {
                // Check if one of the items is "Others" and move it to the end
                if (a.city_name === "Others") return 1;
                if (b.city_name === "Others") return -1;


                return a.city_name.localeCompare(b.city_name);
            });
            setCountryData(sortedData);
        } catch (error) {
            console.error('Error fetching country data:', error);
        }
    };

    useEffect(() => {
        if (filterCountry) {
            countriesGetById();
        }

    }, [filterCountry])
    useEffect(() => {
        IndustriesGet();

    }, [])
    const IndustriesGet = async () => {
        const response = await axiosApi.get("/industries");
        setIndustries(response?.data)
    }


    const handleIndustryChange = (e) => {
        const selectedIndustryId = e.target.value;
        const selectedIndustry = industries.find(industry => industry.id === selectedIndustryId);

        setSelectedIndustryName(selectedIndustry ? selectedIndustry.name : '');
        setformValues({ ...formValues, industry: selectedIndustryId });

        if (selectedIndustryId) {
            axiosApi.get(`/industries/${selectedIndustryId}`)
                .then(response => {
                    setSubcategories(response.data.occupation_skills || []);
                })
                .catch(error => {
                    console.error("There was an error fetching the subcategories!", error);
                    setSubcategories([]);
                });
        } else {
            setSubcategories([]);
        }
    };
    const handleCategoryChange = (e) => {
        const selectedSkillName = e.target.value;
        setSelectedSkillName(selectedSkillName);
        setformValues({ ...formValues, occupation_skill: selectedSkillName });
    };






    const handleSubmit = async (data) => {
        setLoading(true);



        try {




            const payloadProject = {
                // recuiter_name: formValues.recuiter_name,
                job_title: formValues.job_title,
                job_description: formValues.job_description,
                experience_level: formValues?.experience_level,
                job_salary: formValues?.job_salary,
                job_salary_usd:formValues?.job_salary_usd,
                need_start: formValues?.need_start,
                min_salary: formValues.min_salary,
                max_salary: formValues.max_salary,
                job_skills: manySkills.map(skill => ({ name: skill })),
                industry: selectedIndustryName,
                occupation_skill: selectedSkillName,
                job_city_new: formValues?.job_city_new,
                job_country_name: formValues?.country_name,
                remote_job: formValues?.remote_job,
                job_address1: formValues?.job_address1,
                job_address2: formValues?.job_address2,
            };

            const result = await axiosApi.post(`/job/${recuiterId}`, payloadProject);
            // alert("Project submit successfully")
            navigate("/recuiter-profile/all_jobs");
            axiosApi.get(`/recuiter/${recuiterId}`).then((response) => {
                // navigate("/")
                // if (redirectTo == "/recuiter-profile/post_a_project") {
                //   return navigate(redirectTo)
                // } else if (response?.data?.status == 0) {
                //   return navigate('/HireFreeLancer/personal-details')
                // } else {
                //   navigate(-1)
                // }

            })


        } catch (error) {
            console.error("Error verifying OTP:", error);

            // Check if the error response contains a custom message
            if (error.response && error.response.data && error.response.data.message) {
                toast.error(error.response.data.message, { position: 'top-right' });
            } else {
                // If no custom message, show a generic error message
                toast.error('Something went wrong', { position: 'top-right' });
            }
        } finally {
            // Turn off loading whether API call is successful or not
            setLoading(false);
        }
    };










    const renderFormContent = () => {
        switch (currentPage) {
            case 0:
                return (
                    <div className='project-posting-category-container'>
                        <div className="project-posting-category">
                            <label for="inputEmail4" className="form-label">Category <span style={{ color: "red" }}>*</span></label>
                            <select
                                onChange={handleIndustryChange}
                                //  onChange={(e) => setformValues({ ...formValues, [e.target.name]: e.target.value })}
                                name='industry' value={formValues?.industry} style={{ boxShadow: "none" }} className="form-select" aria-label="Default select example">
                                <option selected>Select Category</option>
                                {industries?.map((item) => (
                                    <>
                                        <option style={{ textTransform: "capitalize" }} value={item?.id}>{item?.name}</option>
                                    </>
                                ))}


                            </select>
                        </div>
                        <div className="project-posting-category">
                            <label for="inputEmail4" className="form-label">Subcategory <span style={{ color: "red" }}>*</span></label>
                            <select
                                onChange={handleCategoryChange}
                                name="occupation_skill"
                                value={formValues.occupation_skill}
                                disabled={!subcategories.length && !selectedIndustry}
                                style={{ boxShadow: "none" }} className="form-select" aria-label="Default select example">
                                <option selected>Select Sub Category</option>
                                {selectedIndustry && subcategories.length === 0 && (
                                    <option value="" disabled>No records found</option>
                                )}
                                {subcategories?.map((item) => (
                                    <>
                                        <option style={{ textTransform: "capitalize" }} value={item?.name}>{item?.name}</option>
                                    </>
                                ))}


                            </select>
                        </div>
                        <div className="project-posting-category">
                            <label for="inputPassword4" className="form-label">Choose your country<span style={{ color: "red" }}>*</span></label>
                            <select
                                onChange={handleInputChange}
                                //  onChange={(e) => setformValues({ ...formValues, [e.target.name]: e.target.value })}
                                name='country_name' value={formValues?.country_name} style={{ boxShadow: "none" }} className="form-select" aria-label="Default select example">
                                <option value={''} selected>Select Country</option>
                                {/* {citiesGetData?.map((item) => (
                                    <>
                                        <option value={item?.city_name} style={{ textTransform: "capitalize" }}  >{item?.city_name}</option>
                                    </>
                                ))} */}
                                {countries && countries?.map((item) => {
                                    return (

                                        <option value={item?.country_name} style={{ textTransform: "capitalize" }}  >{item?.country_name}</option>


                                    )
                                })}

                            </select>
                        </div>
                        <div className="project-posting-category">
                            <label for="inputPassword4" className="form-label">Choose your city<span style={{ color: "red" }}>*</span></label>
                            <select
                                onChange={handleInputChange}
                                //  onChange={(e) => setformValues({ ...formValues, [e.target.name]: e.target.value })}
                                name='job_city_new' value={formValues?.job_city_new} style={{ boxShadow: "none" }} className="form-select" aria-label="Default select example">
                                <option value={''} selected>Select city</option>

                                {countryData && countryData?.map((item) => {
                                    return (

                                        <option value={item?.city_name} style={{ textTransform: "capitalize" }}  >{item?.city_name}</option>


                                    )
                                })}

                            </select>
                        </div>
                    </div>
                );

            case 1:
                return (

                    <div className='project-posting-container'>

                        {!apiResponse && (
                            <label>

                                <textarea
                                    type="text"
                                    rows="4"
                                    placeholder="Enter a few bullet points or a full description related to specific requirements of your job."
                                    name="input_prompt"
                                    value={projectdescp?.input_prompt}
                                    onChange={handleDecpChange}
                                />
                            </label>
                        )}




                        {apiResponse && (
                            <div className="api-response-container">
                                <div className='project-posting-container'>
                                    {/* <h2>Project Posting</h2> */}
                                    <label>
                                        Job Title:
                                        <input
                                            type="text"
                                            placeholder='Enter the Job Title  '
                                            name="job_title"
                                            value={formValues.job_title}
                                            onChange={handleInputChange}
                                            style={{ fontSize: "15px" }}
                                        />
                                    </label>
                                    <label  >
                                        <div>
                                            <span> Job Description:</span>
                                            <span className='ms-2'><i style={{ fontWeight: "400", fontSize: "17px" }} >(You can customize the below text)</i></span>
                                        </div>

                                        <textarea
                                            type="text"
                                            rows='20'
                                            placeholder="Describe your Job here... (Minimum 100 characters)"
                                            name="job_description"
                                            value={formValues.job_description}
                                            style={{ resize: "none" }}
                                            onChange={handleInputChange}


                                        />
                                    </label>
                                </div>
                                <div>

                                    <DeleteIcon onClick={() => handleConfirmation(false)} style={{ color: "red" }} /> Clear

                                </div>
                            </div>
                        )}

                    </div>
                );
            case 2:
                return (
                    <>
                        <div className='project-posting-category-container'>
                            <div className="project-posting-category">
                                <label for="inputEmail4" className="form-label">What’s your preferred start date?  <span style={{ color: "red" }}>*</span></label>
                                <select
                                    onChange={handleInputChange}
                                    name="need_start"
                                    value={formValues.need_start}
                                    // disabled={!subcategories.length && !selectedIndustry}
                                    style={{ boxShadow: "none" }} class="form-select" aria-label="Default select example">

                                    <option selected>Select Role</option>
                                    <option style={{ textTransform: "capitalize" }} value='Immediately'>Immediately</option>
                                    <option style={{ textTransform: "capitalize" }} value='In 1 to 2 weeks'>In 1 to 2 weeks</option>
                                    <option style={{ textTransform: "capitalize" }} value='More than 2 weeks from now'>More than 2 weeks from now</option>

                                    <option style={{ textTransform: "capitalize" }} value='`I` ll decide later'>I'll decide later</option>



                                </select>
                            </div>
                            <div className="project-posting-category">
                                <label for="inputEmail4" className="form-label">Experience Level Required  <span style={{ color: "red" }}>*</span></label>
                                <select
                                    onChange={handleInputChange}

                                    name='experience_level'
                                    value={formValues?.experience_level}
                                    style={{ boxShadow: "none" }}
                                    class="form-select"
                                    aria-label="Default select example">
                                    <option selected>Select experiance level</option>
                                    <option style={{ textTransform: "capitalize" }} value='Begineer'>Begineer</option>
                                    <option style={{ textTransform: "capitalize" }} value='Intermediate'>Intermediate</option>
                                    <option style={{ textTransform: "capitalize" }} value='Experiance'>Experiance</option>

                                </select>
                            </div>
                            <div className="project-posting-category">
                                <label for="inputPassword4" className="form-label">  Educational qualification  ?<span style={{ color: "red" }}>*</span></label>

                                <select
                                    onChange={handleInputChange}

                                    name='education_level'
                                    value={formValues?.education_level}
                                    style={{ boxShadow: "none" }}
                                    class="form-select"
                                    aria-label="Default select example">
                                    <option selected>Select Education Qualification</option>
                                    <option value='10th'>10th</option>
                                    <option value='12th'>12th</option>
                                    <option value='Diploma'>Diploma</option>
                                    <option value='Bachelor'>Bachelor</option>
                                    <option value='Master'>Master</option>
                                    <option value='Phd'>Phd</option>


                                </select>


                            </div>
                            {formValues?.country_name === "India" ?
                                <div className="project-posting-category">
                                    <label for="inputPassword4" className="form-label">Salary Range<span style={{ color: "red" }}>*</span></label>
                                    <select
                                        onChange={handleSalaryProject}
                                        name="job_salary"
                                        value={formValues.job_salary}

                                        style={{ boxShadow: "none" }} class="form-select" aria-label="Default select example">

                                        <option selected>Select Salary</option>
                                        <option value='Less than 1 LPA'>Less than 1 LPA</option>
                                        <option value='1 to 2 LPA'>1 to 2 LPA</option>
                                        <option value='2 TO 3 LPA'>2 to 3 LPA</option>
                                        <option value='3 to 4 LPA'>3 to 4 LPA</option>
                                        <option value='4 to 5 LPA'>4 to 5 LPA</option>
                                        <option value='5 to 8 LPA'>5 to 8 LPA</option>
                                        <option value='8 to 10 LPA'>8 to 10 LPA</option>
                                        <option value='10 to 15 LPA'>10 to 15 LPA</option>
                                        <option value='15+ LPA'> 15+ LPA</option>



                                    </select>
                                </div>
                                :
                                // -----------------Salary for USD ----------------------//
                                <div className="project-posting-category">
                                    <label for="inputPassword4" className="form-label">Salary Range<span style={{ color: "red" }}>*</span></label>
                                    <select
                                        onChange={handleSalaryProject}
                                        name="job_salary_usd"
                                        value={formValues.job_salary_usd}

                                        style={{ boxShadow: "none" }} class="form-select" aria-label="Default select example">

                                        <option selected>Select Salary</option>
                                        <option value='Less than 1 US$ 50,000'>Less than 1 US$ 50,000</option>
                                        <option value='US$ 50,000 to 1,00,000'>US$ 50,000 to 1,00,000</option>
                                        <option value='US$ 1,00,000 to 2,00,000'>US$ 1,00,000 to 2,00,000</option>
                                        <option value='US$ 2,00,000 to 5,00,000'>US$ 2,00,000 to 5,00,000</option>
                                        <option value='US$ 5,00,000 to 10,00,000'>US$ 5,00,000 to 10,00,000</option>
                                        <option value='US$ 10,00,000+'>US$ 10,00,000+</option>
                                        



                                    </select>
                                </div>
                            }


                        </div>




                    </>
                );
            case 3:
                return (
                    <>
                        <div className='project-posting-skills-container'>
                            <div className="project-posting-skill">
                                <h4> <i>we recommend to add 4-5 skills for your better connection with our freelancers </i></h4>

                                <div className="skill-add-container">
                                    <input
                                        type="text"
                                        value={skill}
                                        fullWidth
                                        onChange={handleChangeskill}
                                        placeholder="Add  more skills "
                                    />
                                    <div className='skill-expertise-button'><button onClick={handleskillSoftware} className='btn btn-dark'>+Add Skill</button></div>
                                </div>

                            </div>
                            <h5> (The following skills have been added as required for your project, you may add or delete as per your specific requirement.)</h5>
                            <div className='skill-expertise-data'>

                                {manySkills && manySkills.length > 0 && manySkills.map((item, index) => (
                                    <>
                                        <div className='software-known-button-conatiner'>
                                            <button key={index} style={{ backgroundColor: "#F5E8DD" }} className='btn btn ms-2 software-known-button'>{item}</button>
                                            <CancelIcon
                                                className='software-konw-icon'

                                                onClick={() => handleSkillDelete(index)}
                                            />
                                        </div>

                                    </>
                                ))}

                            </div>
                        </div>


                    </>

                );
            case 4:
                return (
                    <>

                        <div className="hire-talent-indutry">
                            <div className="hire-talent-industry-inner">
                                <label htmlFor=""> Address 1 <span style={{ color: "red" }}>*</span> </label>
                                <input type="text"
                                    placeholder='Enter the address1'
                                    onChange={handleInputChange}
                                    value={formValues?.job_address1}
                                    name="job_address1" id="" />
                            </div>
                            <div className="hire-talent-industry-inner">
                                <label htmlFor="">Address 2   </label>
                                <input type="text"
                                    placeholder='Enter the address2'
                                    onChange={handleInputChange}
                                    value={formValues?.job_address2}

                                    name="job_address2" id="" />
                            </div>
                            <div class="hire-talent-industry-inner">
                                <label class="form-check-label ms-2" for="flexSwitchCheckDefault">Remote Job</label>

                                {/* <input class="form-check-input" type="checkbox" checked={formValues.remote_job} onChange={handleRemoteJobChange} id="flexSwitchCheckDefault" /> */}
                                <Switch checked={formValues.remote_job}
                                    onChange={handleRemoteJobChange}
                                    inputProps={{ 'aria-label': 'Remote job toggle' }} />
                            </div>

                        </div>


                    </>

                );


            default:
                return (
                    <div>
                        <h2>Your JOb submitted</h2>


                    </div>
                );
        }
    };

   

    const validatePage = () => {
        const toastOptions = {
            position: 'top-left',
            autoClose: 10000,
            hideProgressBar: false,
        };



        if (currentPage === 0) {
            const { industry, occupation_skill, job_city_new } = formValues;
            if (!industry) {
                toast.error('Please fill the Industry fields', toastOptions);
                // setErrorMessage("Please fill the Industry fields");
                return false;
            } if (!occupation_skill) {
                toast.error('Please fill the Ocuupation skill fields', toastOptions);
                // setErrorMessage("Please fill the Ocuupation skill fields");
                return false;
            } if (!job_city_new) {
                toast.error('Please fill the city new fields', toastOptions);
                // setErrorMessage("Please fill the city new fields");
                return false;
            }
        }
        if (currentPage === 2) {
            const { need_start, experience_level, education_level ,job_salary_usd ,job_salary , country_name } = formValues;
            if (!need_start) {
                toast.error('Please select the start date', toastOptions);
                // setErrorMessage("Please fill the Industry fields");
                return false;
            }
             if (!experience_level) {
                toast.error('Please select the experience level', toastOptions);
                // setErrorMessage("Please fill the Ocuupation skill fields");
                return false;
            } 
            if (!education_level) {
                toast.error('Please select the education qualification', toastOptions);
                // setErrorMessage("Please fill the city new fields");
                return false;
            }
            if (country_name === "India") {
                if (!job_salary) {
                    toast.error('Please select the salary range', toastOptions);
                    // setErrorMessage("Please fill the city new fields");
                    return false;
                }
            } else {
                if (!job_salary_usd) {
                    toast.error('Please select the salary range', toastOptions);
                    // setErrorMessage("Please fill the city new fields");
                    return false;
                }
            }
        

          
          
        }

        if (currentPage === 4) {
            const { job_address1, } = formValues;
            if (!job_address1) {
                toast.error('Please fill the address ', toastOptions);
                // setErrorMessage("Please fill the Industry fields");
                return false;
            }
        }





        return true;
    };

    return (
        <>
            {loading && <LoadingPannel />}
            <div className="global-projectpost">
                <div className="global-project-post-container">
                    <div className="global-flex1">
                        <div className="post-project-logo"><Link to={'/'}><img src="/image/nbflogo.png" alt="" /></Link> </div>
                        <div className='project-post-heading'>


                            <h1>The Right Job, The Right Talent <br /> Just a  <span style={{ color: "#FF3131" }}>Post Away.</span> </h1>
                            <p>We will help you  create the perfect brief. The more detail you provide the better.</p>



                        </div>


                        {/* <textarea name="" rows='4' placeholder='Enter a few bullet points or a full description.' id=""></textarea> */}
                        <div className="form-content">{renderFormContent()}</div>

                        <div className='project-button'>
                            {currentPage > 0 && (
                                <button onClick={handlePrev} className="btn btn-secondary">
                                    Previous
                                </button>
                            )}

                            {/* Show the Submit button only on the second page */}
                            {/* {currentPage === 1 && !apiResponse && (
                                <button type="button" onClick={descriptionapi} className="btn btn-danger">
                                    Submit
                                </button>
                            )} */}
                            {currentPage < totalPages - 1 && (
                                <button
                                    type="button"
                                    onClick={handleNext}
                                    className={`btn btn-primary ${currentPage === 1 && !apiResponse ? '' : ''}`}
                                >
                                    Next
                                </button>
                            )}
                            {currentPage === totalPages - 1 && (
                                <button
                                    type="button"
                                    onClick={handleSubmit}
                                    className="btn btn-success"
                                >
                                    Save
                                </button>
                            )}


                        </div>
                    </div>
                    <div className="global-flex2-job">
                        <img src="/image/jobrobot.webp" alt="" />
                    </div>

                </div>
                <ToastContainer />
            </div>
        </>

    )
}
export default JobPostLogin
