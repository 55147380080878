import React, { useEffect, useState } from 'react'
import Footer from '../../Layout/Footer/Index'
import Header from '../../Layout/Header/Index';
import "./viewprojectdetails.scss"
import { axiosApi } from '../../_helper/api_helper';
import { Button } from 'react-bootstrap';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import { useParams } from 'react-router-dom';


const ViewAllProjectDetail = () => {
  const { projectid, recuiterid } = useParams();
  const [projectbyId, setProjectById] = useState({});
  const [recentProject, setRecentProject] = useState([]);
  const [bidFreelancer, setBidFreelancer] = useState([]);
  const countryFlags = {
    India: "/flag/india.png",
    Australia: "/flag/australia.png",
  };
  console.log("projectidrecuiterid", projectbyId)
  useEffect(() => {
    recentprojectApi();
    recentBidFreelancerApi();
  }, [])


  const recentBidFreelancerApi = async () => {
    try {
      const response = await axiosApi.get('/clients/filter');
      setBidFreelancer(response.data);
    } catch (error) {

    }
  }
  const recentprojectApi = async () => {
    try {
      const response = await axiosApi.get('/multijobs/all');
      setRecentProject(response.data);
    } catch (error) {


    }
  }
 

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosApi.get(`/multijob/${recuiterid}/${projectid}`);
        setProjectById(response?.data);
      } catch (error) {
        console.error("Error fetching recruiter details:", error);
      }
    };
    // fetchData()
    if (projectid) {
      fetchData();
    }
  }, [projectid]);

  const calculateTimeElapsed = (createdAt) => {
    const currentDate = new Date();
    const postedDate = new Date(createdAt);
    const timeDifference = currentDate - postedDate;

    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const weeks = Math.floor(days / 7);
    const months = Math.floor(days / 30);
    const years = Math.floor(days / 365);

    if (years > 0) {
      return `${years} ${years === 1 ? 'year' : 'years'} ago`;
    } else if (months > 0) {
      return `${months} ${months === 1 ? 'month' : 'months'} ago`;
    } else if (weeks > 0) {
      return `${weeks} ${weeks === 1 ? 'week' : 'weeks'} ago`;
    } else if (days > 0) {
      return `${days} ${days === 1 ? 'day' : 'days'} ago`;
    } else if (hours > 0) {
      return `${hours} ${hours === 1 ? 'hour' : 'hours'} ago`;
    } else if (minutes > 0) {
      return `${minutes} ${minutes === 1 ? 'minute' : 'minutes'} ago`;
    } else {
      return `${seconds} ${seconds === 1 ? 'second' : 'seconds'} ago`;
    }
  };
  return (
    <>
      <Header />

      {/* <div className="view-details-image">
        <img src="/image/project-detail.png" alt="" />
      </div> */}
      <div className="view-project-details">
        <div className="view-project-details-container">
          <div className="view-project-detail-flex1">
            <div className="view-project-detail-flex1-container">
              <div className="view-details-heading">
                <h3>{projectbyId?.project_title?.replace(/^"|"$/g, '')}</h3>
                <h3>{projectbyId.min_perhour_usd || projectbyId.min_fixedprice_usd && (
                  <span className='ms-3' style={{ color: "green", fontWeight: "600", padding: "8px", borderRadius: "5px" }}>
                    US$ {projectbyId.min_perhour_usd && projectbyId.max_perhour_usd
                      ? `${projectbyId.min_perhour_usd} - ${projectbyId.max_perhour_usd}`
                      : projectbyId.min_fixedprice_usd && projectbyId.max_fixedprice_usd
                        ? `${projectbyId.min_fixedprice_usd} - ${projectbyId.max_fixedprice_usd}`
                        : 'null'}
                  </span>
                )}</h3>
              </div>
              <div className="view-details-dated">
                <h6>Posted {calculateTimeElapsed(projectbyId?.created_at)}</h6>
              </div>
              <div className="view-details-skill">
                <h6>Skill Required</h6>
           

                  {projectbyId?.skills ? (
                    <span className='' style={{ color: "black", fontWeight: "500", display: "flex", flexWrap: "wrap", gap: "5px" }}>
                      {projectbyId.skills.map((skill, index) => (
                        <button key={index} className='btn btn-light'>{skill?.name}</button>
                      ))}
                    </span>
                  ) : (
                    <span>No skills specified</span>
                  )}
                
              </div>
              <div className="view-details-skill">
                <h6>Industry Required </h6>
                <div> <button style={{ background: "#F9E8C9", padding: "0.5rem", textTransform: "capitalize" }} className='btn btn-light'>{projectbyId?.industry}</button></div>
              </div>
              <div className="view-details-para">
                <pre style={{ whiteSpace: "pre-wrap", fontFamily: `"Poppins", sans-serif` }}
                  dangerouslySetInnerHTML={{
                    __html: projectbyId?.project_description?.replace(/\*\*(.*?)\*\*/g, "<b>$1</b>"),
                  }}
                >
                  {/* {recuiterById?.project_description} */}
                </pre>
              </div>
            </div>
            <h4>121 freelancers are bidding on average $1,119 CAD for this job</h4>
            <div className="recentbidfreelancer">
              {bidFreelancer?.splice(0, 10).map((item) => (
                 
                <div className="allrecuiter-content" key={item.project_id}>
                  <div className='bidfreelancer-image'>
                    <img src={`${axiosApi?.defaults?.baseURL}${item?.profile_pic}`} alt="" />
                  </div>
                  <div className='view-details-title'>
                    <h5>{item?.name}</h5>
                    <h4 className='job-hostedby'><BusinessCenterIcon /> {item?.Occupation_industry}</h4>
                    <p>{item?.about?.slice(0, 100)}...</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="view-project-detail-flex2">
            <div className="view-project-detail-flex2-container">
              <h4>Place your bid</h4>
              <div className="bid-now-input">
                <label>Enter Bid Amount:</label>
                <input
                  type="number"
                  className="form-control"
                  value=''
                  placeholder='Enter Bid Amount'
                // onChange={(e) => setBidAmount(e.target.value)}
                />
              </div>
              <Button variant="dark"  >Submit your Bid </Button>
            </div>
            <div className="project-benefits">
              <h5>Benefits of bidding on Freelancer</h5>
            </div>

            <div className="project-cards">
              <h5>Similar Project</h5>
              {recentProject?.splice(0, 10).map((item) => (
                <div className="allrecuiter-content" key={item.project_id}>
                  {/* <div className="flag-project-container">
                      {countryFlags[item?.country_name] ? (
                        <img src={countryFlags[item?.country_name]} alt="Country Flag" className="country-flag" />
                      ) : null}

                    </div> */}
                  <div className='job-title'>
                    <h5>{item?.project_title?.replace(/^"|"$/g, '')}</h5>

                  </div>

                  <h4 className='job-hostedby'>Hosted by {item?.recruiter_organisation_name || item?.recruiter_name}</h4>
                  <h4 className='job-category'  >{item?.project_occupation_skill || item?.occupation_skill}</h4>
                  {/* {item?.project_city_new && (  <div className="applicant-location-name"><img src="/svg/location.svg" width={"20px"} height={'20px'} alt="" /> {item?.project_city_new}</div>)} */}
                  {/* <div className="applicant-education-name">₹ {item?.min_perhour && item?.max_perhour
                        ? `${item.min_perhour} - ${item.max_perhour} Per/Hour`
                        : item?.min_fixedprice && item?.max_fixedprice
                          ? `${item.min_fixedprice} - ${item.max_fixedprice} Fix Price`
                          : 'N/A'}
                      </div> */}

                  <div className="applicant-education-price"> US$ {item?.min_perhour_usd && item?.max_perhour_usd
                    ? `${item.min_perhour_usd} - ${item.max_perhour_usd} Per/Hour`
                    : item?.min_fixedprice_usd && item?.max_fixedprice_usd
                      ? `${item.min_fixedprice_usd} - ${item.max_fixedprice_usd} Fix Price`
                      : 'N/A'}
                  </div>

                  <div className='hiretalent-content-about'>{item?.project_description?.slice(0, 100)}...</div>



                </div>
              ))}
            </div>

          </div>


        </div>
      </div>


      {/* <Footer /> */}

    </>
  )
}

export default ViewAllProjectDetail
