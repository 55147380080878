import { combineReducers } from "redux"
// import GetDeatilsReducer from "./besthost/reducer";
import PostLoginReducer from "./login/reducer";
import RegistrationProfileReducer from "./registrationprofilefreelancer/reducer";
import EducationRegReducer from "./education/reducer";
import CertificateReducer  from "./certification/reducer";
import PortfolioRedicers  from "./portfolio/reducer";
import LoginRecuritReducer from "./recuritLogin/reducer";
import RecuiterFormReducers from "./recuiterProfileForm/reducer"
import FiiterReducersData from "./getallfilterpublic/reducer";
import BookingDetails from "./booking/reducer";
import { IndustriesReducers } from "./industries/reducer";


const rootReducer = () => {
    return combineReducers({
        // GetDeatilsReducer,
        PostLoginReducer,
        RegistrationProfileReducer,
        EducationRegReducer,
        CertificateReducer,
        PortfolioRedicers,
        LoginRecuritReducer,
        FiiterReducersData,
        RecuiterFormReducers,
        BookingDetails,
        IndustriesReducers

    })
}
export default rootReducer;