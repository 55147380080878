import { INDUSTRIES_GET, INDUSTRIES_GET_FAIL, INDUSTRIES_GET_SUCCESS } from "./actiontype";

const initalValues = {
    getIndustries: [],
    loading: false,
    error: {
        message: ""
    }
}

export const IndustriesReducers = (state = initalValues, action) => {
    
    if (!action) return state;
    switch (action.type) {
        
        case INDUSTRIES_GET:
            state = { ...state, loading: true };
            break;
        case INDUSTRIES_GET_SUCCESS:
            state = { ...state, getIndustries: action.payload, loading: false };
            break;
            case INDUSTRIES_GET_FAIL:
                state = {
                    ...state,
                    error: {
                        message: "Error",
                    },
                    loading: false,
                };
                break;
        default:
            state={...state};
            break
    }
    return state;
}